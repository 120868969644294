import axios from "clients/axios";

export const getHomeIsolationService = async () => {
  try {
    const res = await axios.get("/agency/isolate");
    const { data } = res.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
export const approveCoupon = async payload => {
  try {
    console.log(payload);
    const res = await axios.get(
      `/syn/homeisolation/approve/${payload}`
    );
    const { data } = res.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
export const approveCouponAll = async payload => {
  payload.forEach(async element => {
    try {
    // console.log(payload);
    const res = await axios.get(
      `/syn/homeisolation/approve/${element.id}`
    );
    const { data } = res.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
  });
  
};
export const rejectCoupon = async payload => {
  try {
    console.log(payload);
    const res = await axios.post(
      `/syn/homeisolation/reject/${payload.id}`,
      {
        remark: payload.cause
      }
    );
    const { data } = res.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
export const getApproveCoupon = async () => {
  try {
    const res = await axios.get("/syn/homeisolation/getpatientadmin/approve");
    const { data } = res.data;
    console.log(res.data);
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
export const getWaitingCoupon = async () => {
  try {
    const res = await axios.get("/syn/homeisolation/getpatientadmin/waiting");
    const { data } = res.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
export const getEmailOverService = async payload => {
  try {
    console.log(payload);
    const res = await axios.get(
      `/syn/homeisolation/getuseemail?email=${payload}`
    );
    const { data } = res.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
