import axios from "clients/axios";

export const getProjectsService = async () => {
  try {
    const res = await axios.get("/agency/getProjectApprove");
    const { data } = res.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getProjectService = async id => {
  try {
    const res = await axios.get(`/agency/getdetail/${id}`);
    const { data } = res.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getAgencyPointService = async id => {
  try {
    const res = await axios.get(`point/getPointByOwnerType/${id}`);
    const { data } = res.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
