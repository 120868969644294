export default {
  ["GET_COUPON_SUCSESS"]: (state, payload) => {
    state.couponApproveDetail = payload;
  },
  ["GET_COUPON_WAITING_SUCSESS"]: (state, payload) => {
    state.couponWaitingDetail = payload;
  },
  ["GET_EMAIL_OVER_SUCSESS"]: (state, payload) => {
    state.emailOver = payload;
  },
};
