<template>
  <div>
    <v-select
      v-if="!isViewing"
      class="i-text-field"
      v-model="localValue"
      :label="label"
      outlined
      dense
      style="border-radius: 6px"
      :hide-details="hideDetails"
      :required="required"
      :rules="rules"
      :disabled="disabled"
      :items="items"
      :item-value="itemValue"
      :item-text="itemText"
      :item-color="itemColor"
      :item-disabled="itemDisabled"
      :multiple="multiple"
    ></v-select>
    <div v-else class="text-view">
      {{ preview }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {},
    value: {},
    hideDetails: {
      default: () => false
    },
    required: {
      default: () => false
    },
    rules: {},
    type: {
      default: () => "text"
    },
    disabled: {
      default: () => false
    },
    items: {
      default: () => []
    },
    itemValue: {},
    itemText: {},
    itemDisabled: {},
    itemColor: {},
    multiple: {},
    isViewing: {}
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    preview() {
      const item = this.items.find(f => f[this.itemValue] === this.localValue) || {};
      return item[this.itemText];
    }
  }
};
</script>

<style>
.i-text-field .v-input__slot {
  background: #fff !important;
}
.i-text-field .v-input__slot .v-label {
  color: #c7c7c7;
}
.v-text-field--outlined fieldset {
  border: 1px solid #e0e0e0;
}
</style>
