<template>
  <div>
    <template>
      <v-row class="ma-0">
        <!-- <pre>{{ items }}</pre> -->
        <v-col cols="12 pa-0" v-for="item in items" :key="item.id">
          <v-card
            class="mx-auto mt-7"
            width="90%"
            outlined
            @click="hospitalItem(item)"
            style="box-shadow: 2px 3px #1e4d94c4;"
          >
            <v-list-item three-line>
              <!-- <div class="image-hi">
                <img :src="`${baseUrl}/media/image${item.image}`" alt="image" />
              </div> -->
              <v-list-item-title class="text-h5 mb-1">
                <v-list-item-subtitle class="card-title-hi">{{ item.name }} </v-list-item-subtitle>
              </v-list-item-title>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
// import { getHomeIsolationService } from "services/home_isolation.service";
// import { mapActions } from "vuex";
import axios from "clients/axios";
const baseUrl = process.env.VUE_APP_BASE_URL_API;

export default {
  data() {
    return {
      items: [],
      baseUrl
    };
  },
  created() {
    // this.getHomeIsolationService();
    this.getItems();
  },
  mounted() {
    // this.getItems();
  },
  methods: {
    async getItems() {
      try {
        const res = await axios.get(`${baseUrl}/agency/isolate`);
        const { data } = res.data;
        this.items = data;
      } catch (error) {
        console.log(error);
      }
    },
    hospitalItem(v){
      this.$router.push({
        name: "isolation_select",
        params: {
          id: v.id
        },
      })
      this.$route.meta.title_hospital_by = v.name
    }
  }
};
</script>

<style scoped>
.card-title-hi {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #333333 !important;
}
.image-hi {
  width: 48%;
  height: 100 px;
  overflow: hidden;
  border-radius: 16 px;
}
</style>
