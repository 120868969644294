<template>
  <div>
    <v-dialog v-model="localDialog" scrollable hide-overlay class="rounded-xl">
      <v-card class="rounded-lg">
        <v-card class="my-0 pa-0 overflow-y-auto">
          <v-container class="py-0">
            <v-card flat>
              <v-card-text class="pa-0">
                <div class="row">
                  <div class="col col-12">
                    <div>
                      <v-card-title
                        class="titleHighlight pb-0"
                        style="font-size: 16px; justify-content: center;"
                      >
                        ข้อกำหนดและเงื่อนไขในการใช้งาน
                      </v-card-title>
                      <v-card-title
                        class="titleHighlight py-0"
                        style="font-size: 16px; justify-content: center;"
                      >
                        น้องปันอิ่ม
                      </v-card-title>
                    </div>
                    <v-card-text>
                      ข้อกำหนดและเงื่อนไขในการใช้งาน น้องปันอิ่ม ฉบับนี้ ("ข้อกำหนดและเงื่อนไขฯ")
                      ได้ระบุถึงข้อกำหนดและเงื่อนไขในการใช้งานผลิตภัณฑ์และบริการใดๆ (เรียกรวมกันว่า
                      “บริการฯ”) ของ บริษัท อินเทอร์เน็ตประเทศไทย จำกัด (มหาชน) ("น้องปันอิ่ม")
                      แก่ผู้ใช้บริการ (โดยแต่ละรายเรียกว่า "ผู้ใช้" หรือ “ผู้ใช้รายต่างๆ”
                      ขึ้นอยู่กับเนื้อหา)
                    </v-card-text>
                    <a name="top" />
                    <v-card-title class="titleHighlight-head"> ข้อ 1. นิยามศัพท์</v-card-title>
                    <v-card-text>
                      <p>
                        คำและข้อความดังต่อไปนี้ให้มีความหมายตามที่ได้กำหนดไว้ด้านล่างเมื่อมีการใช้ในข้อกำหนดและเงื่อนไขฯ
                        ฉบับนี้
                      </p>
                      <ol>
                        <li>
                          "เนื้อหา" หมายถึง ข้อมูลต่างๆ เช่น ข้อความ เสียง เพลง รูปภาพ วิดีโอ
                          ซอฟต์แวร์ โปรแกรม รหัสคอมพิวเตอร์ และข้อมูลอื่นๆ
                        </li>
                        <li>
                          "เนื้อหาหลัก" หมายถึง เนื้อหาที่สามารถเข้าถึงได้ผ่านทางบริการฯ
                        </li>
                        <li>
                          "เนื้อหาจากผู้ใช้" หมายถึง เนื้อหาที่ผู้ใช้ได้ส่ง ส่งผ่าน หรือ
                          อัพโหลดบนระบบการให้บริการฯ
                        </li>
                        <li>
                          "เหรียญ" หมายถึง
                          ตราสารการชำระหนี้แบบเติมเงินหรือสิ่งอื่นใดที่มีความคล้ายคลึงกัน
                          ซึ่งผู้ใช้สามารถใช้ตราสารดังกล่าวแลกเปลี่ยนกับเนื้อหาและบริการต่างๆ
                          โดยเสียค่าตอบแทนซึ่ง น้องปันอิ่ม เป็นผู้ให้บริการ
                        </li>
                      </ol>
                    </v-card-text>

                    <v-card-title class="titleHighlight-head">
                      ข้อ 2. การตกลงยอมรับข้อกำหนดและเงื่อนไขฯ ฉบับนี้
                    </v-card-title>
                    <v-card-text>
                      <ol>
                        <li>
                          ผู้ใช้ทุกรายจะต้องใช้บริการฯ ตามข้อกำหนดที่ระบุไว้ในข้อกำหนดและเงื่อนไขฯ
                          ฉบับนี้ โดยผู้ใช้จะไม่สามารถใช้บริการฯ
                          ได้เว้นเสียแต่ผู้ใช้ได้ตกลงยอมรับข้อกำหนดและเงื่อนไขฯ ฉบับนี้แล้ว
                        </li>
                        <li>
                          ผู้ใช้ซึ่งเป็นผู้เยาว์จะสามารถใช้บริการฯ
                          ได้ก็ต่อเมื่อได้รับความยินยอมล่วงหน้าจากบิดามารดาหรือผู้แทนโดยชอบกฎหมายเท่านั้น
                          นอกจากนี้ หากผู้ใช้ดังกล่าวใช้บริการฯ
                          ในนามหรือเพื่อวัตถุประสงค์ขององค์กรธุรกิจใด
                          ให้ถือว่าองค์กรธุรกิจดังกล่าวได้ตกลงยอมรับข้อกำหนดและเงื่อนไขฯ
                          ฉบับนี้แล้วล่วงหน้า
                        </li>
                        <li>
                          หากมีข้อกำหนดและเงื่อนไขฯ เพิ่มเติมใดๆ ซึ่งเกี่ยวข้องกับการให้บริการฯ
                          ผู้ใช้จะต้องปฏิบัติตามข้อกำหนดและเงื่อนไขฯ
                          เพิ่มเติมดังกล่าวเช่นเดียวกับข้อกำหนดและเงื่อนไขฯ ในการใช้งานฉบับนี้
                        </li>
                      </ol>
                    </v-card-text>

                    <v-card-title class="titleHighlight-head">
                      ข้อ 3. การแก้ไขข้อกำหนดและเงื่อนไขฯ ฉบับนี้
                    </v-card-title>
                    <v-card-text>
                      <p>
                        น้องปันอิ่ม อาจเปลี่ยนแปลงแก้ไขข้อกำหนดและเงื่อนไขฯ ฉบับนี้ได้ตลอดเวลาตามที่
                        น้องปันอิ่ม เห็นสมควรซึ่งจะอยู่ภายใต้ขอบวัตถุประสงค์ของข้อกำหนดและเงื่อนไขฯ
                        ฉบับนี้ ในกรณีดังกล่าว น้องปันอิ่ม
                        จะแจ้งเนื้อหาของข้อกำหนดฉบับแก้ไขรวมถึงวันที่มีผลบังคับใช้บนเว็บไซต์ของ
                        น้องปันอิ่ม หรืออาจแจ้งให้ผู้ใช้ทราบด้วยวิธีการอื่นใดตามที่ น้องปันอิ่ม
                        กำหนด ทั้งนี้ ข้อกำหนดและเงื่อนไขฉบับแก้ไขจะมีผลบังคับใช้ตามวันที่กำหนดต่อไป
                      </p>
                    </v-card-text>

                    <v-card-title class="titleHighlight-head"> ข้อ 4. บัญชี</v-card-title>
                    <v-card-text>
                      <ol>
                        <li>
                          เมื่อใช้บริการฯ ผู้ใช้อาจมีความจำเป็นต้องลงทะเบียนการใช้บริการฯ
                          ด้วยข้อมูลบางประการ ทั้งนี้ ผู้ใช้ต้องให้ข้อมูลที่เป็นความจริง ถูกต้อง
                          ครบถ้วนและมีหน้าที่ต้องปรับปรุงและแก้ไขข้อมูลดังกล่าวให้เป็นปัจจุบันอยู่เสมอ
                        </li>
                        <li>
                          ในกรณีที่ผู้ใช้ลงทะเบียนข้อมูลการยืนยันตัวตนใดๆ (Authentication
                          information) เมื่อใช้บริการฯ
                          ผู้ใช้ต้องใช้ความระมัดระวังในการจัดการข้อมูลดังกล่าวด้วยความรับผิดชอบของตน
                          เพื่อให้เป็นที่แน่ใจว่าข้อมูลดังกล่าวจะไม่ถูกนำไปใช้ในลักษณะที่ไม่ชอบด้วยกฎหมาย
                          ทั้งนี้ น้องปันอิ่ม อาจถือว่ากิจกรรมใดๆ
                          ซึ่งดำเนินการโดยการใช้ข้อมูลการรับรองตัวตนดังกล่าว
                          เสมือนเป็นกิจกรรมที่ผู้เป็นเจ้าของข้อมูลได้ดำเนินการด้วยตนเองทั้งสิ้น
                        </li>
                        <li>
                          ผู้ใช้ซึ่งลงทะเบียนใช้บริการฯ สามารถลบบัญชีของตนและยกเลิกการใช้บริการฯ
                          ได้ไม่ว่าในเวลาใด
                        </li>
                        <li>
                          น้องปันอิ่ม ขอสงวนสิทธิในการลบบัญชีใดๆ ซึ่งไม่มีการเปิดใช้งานเป็นเวลากว่า
                          หนึ่ง (1) ปี
                          หรือนานกว่านับแต่วันที่มีการเปิดใช้งานบัญชีดังกล่าวครั้งล่าสุด ทั้งนี้
                          โดยไม่ต้องบอกกล่าวล่วงหน้าใดๆ แก่ผู้ใช้บัญชีดังกล่าว
                        </li>
                        <li>
                          สิทธิใดๆ
                          ของผู้ใช้บริการอาจสิ้นสุดลงเมื่อบัญชีของผู้ใช้ดังกล่าวถูกลบไม่ว่าด้วยเหตุผลประการใดๆ
                          ทั้งนี้ บัญชีการใช้บริการฯ จะไม่สามารถกู้คืนได้ถึงแม้ว่าผู้ใช้บริการฯ
                          จะลบบัญชีของตนโดยไม่ได้ตั้งใจก็ตาม
                        </li>
                        <li>
                          บัญชีแต่ละบัญชีในการใช้บริการฯ นั้น
                          มีไว้เพื่อการใช้งานเฉพาะบุคคลและเป็นของเจ้าของบัญชีนั้นแต่เพียงผู้เดียว
                          ผู้ใช้ไม่สามารถโอน ให้ยืม หรือจำหน่ายสิทธิในการใช้บัญชีของตนแก่บุคคลภายนอก
                          ขณะเดียวกันบุคคลภายนอกก็ไม่สามารถรับช่วงสิทธิหรือสืบทอดบัญชีจากผู้ใช้ดังกล่าวได้เช่นกัน
                        </li>
                      </ol>
                    </v-card-text>

                    <v-card-title class="titleHighlight-head">
                      ข้อ 5. การให้บริการฯ
                    </v-card-title>
                    <v-card-text>
                      <ol>
                        <li>
                          ผู้ใช้จะเป็นผู้รับผิดชอบจัดหาเครื่องคอมพิวเตอร์ อุปกรณ์สื่อสารเคลื่อนที่
                          สมาร์ทโฟน อุปกรณ์สื่อสารอื่นๆ ระบบปฏิบัติการ
                          วิธีการเชื่อมต่อสื่อสารและกระแสไฟฟ้า ฯลฯ ซึ่งจำเป็นต่อการใช้บริการฯ
                          ทั้งนี้ ผู้ใช้เป็นผู้ออกค่าใช้จ่ายเองทั้งสิ้น
                        </li>
                        <li>
                          น้องปันอิ่ม ขอสงวนสิทธิในการจำกัดการเข้าถึงการใช้บริการฯ
                          ทั้งหมดหรือแต่บางส่วนของผู้ใช้บริการฯ
                          การสงวนสิทธิดังกล่าวขึ้นอยู่กับเงื่อนไขที่ น้องปันอิ่ม เห็นสมควร เช่น
                          อายุและการระบุตัวตนของผู้ใช้บริการฯ การลงทะเบียน สถานภาพ
                          และองค์ประกอบอื่นใดที่มีความคล้ายคลึงกัน
                        </li>
                        <li>
                          น้องปันอิ่ม ขอสงวนสิทธิในการแก้ไขหรือเปลี่ยนแปลงข้อกำหนดและเงื่อนฯ
                          ในการใช้บริการทั้งหมดหรือแต่บางส่วนไม่ว่าในเวลาใด ตามที่ น้องปันอิ่ม
                          เห็นสมควร ทั้งนี้ โดยไม่ต้องบอกกล่าวล่วงหน้าแก่ผู้ใช้
                        </li>
                        <li>
                          น้องปันอิ่ม อาจหยุดการให้บริการฯ
                          ทั้งหมดหรือแต่บางส่วนโดยไม่ต้องบอกกล่าวล่วงหน้าแก่ผู้ใช้ในกรณีดังต่อไปนี้
                          <ol>
                            <li>
                              เมื่อทำการบำรุงรักษาหรือซ่อมแซมระบบ
                            </li>
                            <li>
                              เมื่อไม่สามารถให้บริการได้เนื่องจากเหตุสุดวิสัยบางประการ เช่น
                              อุบัติเหตุ (ไฟไหม้ไฟดับ ฯลฯ ) ปรากฏการณ์ธรรมชาติ สงคราม การจลาจล
                              และข้อพิพาทแรงงาน
                            </li>
                            <li>
                              เมื่อระบบเกิดความขัดข้องหรือมีการใช้งานระบบมากเกินกำลัง (Overload)
                            </li>
                            <li>
                              เพื่อรักษาความปลอดภัยของผู้ใช้หรือบุคคลภายนอกหรือเพื่อดำเนินภาระกิจอันเป็นประโยชน์ต่อสาธารณะในกรณีฉุกเฉิน
                            </li>
                            <li>
                              เมื่อ น้องปันอิ่ม
                              เห็นว่ามีความจำเป็นและเป็นการสมควรนอกจากกรณีที่ได้ระบุไว้ในข้อ (1) ถึง
                              (4) ข้างต้น
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </v-card-text>

                    <v-card-title class="titleHighlight-head">
                      ข้อ 6. เหรียญ
                    </v-card-title>
                    <v-card-text>
                      <ol>
                        <li>
                          ผู้ใช้จะได้รับเหรียญโดยวิธีการที่ น้องปันอิ่ม กำหนด เช่น
                          การซื้อในระบบการให้บริการฯ การเสนอให้เป็นพิเศษ และวิธีการอื่นๆ
                          ในทำนองเดียวกัน ทั้งนี้ รายละเอียดเกี่ยวกับค่าเงิน วิธีการชำระเงิน
                          และเงื่อนไขอื่นๆ
                          เกี่ยวกับการได้รับเหรียญจะถูกกำหนดและแสดงอยู่บนระบบการให้บริการฯ
                          หรือบนเว็บไซต์ของ น้องปันอิ่ม
                        </li>
                        <li>
                          เหรียญนั้นไม่สามารถนำไปแลกเปลี่ยน ใช้แทนเงินหรือทรัพย์สิน
                          หรือใช้เพื่อวัตถุประสงค์ทางการเงินอื่นนอกจากที่บริการฯ และเนื้อหาของ
                          น้องปันอิ่ม กำหนด จำนวนเหรียญที่จำเป็นต่อการรับบริการฯ
                          หรือใช้เพื่อเนื้อหาหรือใช้เพื่อสิ่งอื่นใดในทำนองเดียวกันจะถูกกำหนดและแสดงอยู่บนระบบการให้บริการฯ
                          หรือบนเว็บไซต์ของ น้องปันอิ่ม
                          6.3.เฉพาะบัญชีที่รับเหรียญเท่านั้นที่จะสามารถใช้งานเหรียญได้ นอกจากนี้
                          เหรียญที่ให้บริการโดยปลายทาง (เทอร์มินอล)
                          ซึ่งมีระบบปฏิบัติการที่แตกต่างกันนั้นไม่สามารถนำมารวมหรือยกยอดเหรียญกันได้
                          เนื่องจากประเภทของเหรียญนั้นมีความแตกต่างกันแม้ว่าเหรียญดังกล่าวจะถูกจัดประเภทให้อยู่ภายใต้ชื่อเดียวกันก็ตาม
                        </li>
                        <li>
                          เหรียญนั้นจะไม่สามารถขอคืนเป็นเงินได้ไม่ว่าในกรณีใดๆ อย่างไรก็ตาม
                          ข้อกำหนดนี้จะไม่มีผลใช้บังคับหากมีกฎหมายและกฏระเบียบที่เกี่ยวข้องกำหนดว่าต้องคืนเงิน
                          ในกรณีดังกล่าว น้องปันอิ่ม
                          จะดำเนินการคืนเงินตามกฎหมายและกฏระเบียบที่เกี่ยวข้อง
                          และจะมีการแสดงข้อมูลดังกล่าวไว้ในเว็บไซต์ของ น้องปันอิ่ม และที่อื่นๆ
                          ตามที่อาจกำหนดต่อไป
                        </li>
                      </ol>
                    </v-card-text>

                    <v-card-title class="titleHighlight-head">
                      ข้อ 7. ข้อจำกัดการใช้งาน
                    </v-card-title>
                    <v-card-text>
                      <p>
                        ผู้ใช้ไม่สามารถกระทำการดังต่อไปนี้เมื่อใช้บริการฯ ได้ ตามที่ น้องปันอิ่ม
                        กำหนด
                      </p>
                      <ol>
                        <li>
                          การกระทำที่ฝ่าฝืนกฎหมาย ข้อบังคับ คำพิพากษา มติหรือคำสั่งศาล
                          หรือมาตรการทางปกครองที่มีผลผูกพันทางกฎหมาย
                        </li>
                        <li>การกระทำที่อาจขัดต่อความสงบเรียบร้อยหรือศีลธรรมอันดีของประชาชน</li>
                        <li>
                          การกระทำที่ละเมิดสิทธิในทรัพย์สินทางปัญญา เช่น ลิขสิทธิ์
                          เครื่องหมายการค้าและสิทธิบัตร สิทธิในชื่อเสียง ความเป็นส่วนตัว
                          และสิทธิอื่นๆ ทั้งหมดที่ได้รับตามกฎหมายหรือตามสัญญากับ น้องปันอิ่ม
                          และ/หรือบุคคลที่สาม
                        </li>
                        <li>
                          การกระทำที่แสดงหรือส่งต่อซึ่งการแสดงออกที่มีลักษณะรุนแรงหรือเกี่ยวข้องกับเพศอย่างชัดเจน
                          การแสดงออกซึ่งภาพอนาจารของเด็กหรือเยาวชนหรือการทารุณกรรมเด็ก
                          การแสดงออกซึ่งนำไปสู่การเลือกปฏิบัติทางด้านเชื้อชาติ สัญชาติ ความเชื่อ เพศ
                          สถานะทางสังคม ถิ่นกำเนิด ครอบครัว และอื่นๆ ที่เกี่ยวข้อง
                          การแสดงออกที่ชักชวนหรือส่งเสริมการฆ่าตัวตาย พฤติกรรมการทำร้ายตัวเอง
                          การใช้ยาในทางที่ผิด หรือการแสดงออกใดๆ
                          ซึ่งเป็นการต่อต้านสังคมอันประกอบด้วยเนื้อหาที่ต่อต้านสังคมและทำให้บุคคลอื่นเกิดความไม่สบายใจเป็นต้น
                        </li>
                        <li>
                          การกระทำที่อาจทำให้ผู้อื่นเข้าใจผิดเกี่ยวกับ น้องปันอิ่ม
                          และ/หรือบุคคลภายนอกหรือการจงใจเผยแพร่ข้อมูลเท็จ
                        </li>
                        <li>
                          กระทำต่างๆ เช่น
                          การส่งข้อความซึ่งมีเนื้อหาเหมือนหรือคล้ายคลึงกันไปให้แก่ผู้ใช้เป็นจำนวนมาก
                          (ยกเว้นข้อความที่ได้รับความเห็นชอบจาก น้องปันอิ่ม)
                          การเพิ่มผู้ใช้รายอื่นเป็นเพื่อนหรือการแชทเป็นกลุ่มแบบสุ่ม
                          หรือการกระทำอื่นใดที่ น้องปันอิ่ม ถือว่าเป็นการส่งข้อความรบกวน (Spamming)
                        </li>
                        <li>
                          การกระทำที่เป็นการแลกเปลี่ยนสิทธิในการใช้เนื้อหาฯ เป็นเงินสด
                          ทรัพย์สินหรือผลประโยชน์ทางการเงินอื่นๆ นอกเสียจากที่ น้องปันอิ่ม กำหนดไว้
                        </li>
                        <li>
                          การกระทำที่มีการใช้บริการฯ เพื่อการขาย การตลาด การโฆษณา การชักชวน
                          หรือวัตถุประสงค์ทางการค้าอื่นๆ (ยกเว้นวัตถุประสงค์ที่ได้รับความเห็นชอบจาก
                          น้องปันอิ่ม) การกระทำที่มีการใช้บริการฯ
                          เพื่อการกระทำทางเพศหรือการกระทำที่อนาจาร การกระทำที่มีการใช้บริการฯ
                          เพื่อวัตถุประสงค์ในการพบปะหรือนัดออกเดทกับบุคคลแปลกหน้าเพื่อการมีเพศสัมพันธ์
                          การกระทำที่ใช้บริการฯ เพื่อวัตถุประสงค์ในการคุกคาม
                          หรือการหมิ่นประมาทใส่ร้ายผู้ใช้รายอื่นๆ หรือการกระทำใดๆ ซึ่งใช้บริการฯ
                          เพื่อวัตถุประสงค์อื่นที่มิใช่จุดประสงค์ที่แท้จริงของการให้บริการฯ
                        </li>
                        <li>การกระทำที่เป็นประโยชน์ต่อหรือมีส่วนร่วมกับกลุ่มที่ต่อต้านสังคม</li>
                        <li>
                          การกระทำที่เกี่ยวกับกิจกรรมทางศาสนาหรือการเชื้อเชิญให้เข้ากลุ่มทางศาสนา
                        </li>
                        <li>
                          การกระทำที่เป็นการเก็บรวบรวม เปิดเผย หรือการให้ข้อมูลส่วนบุคคลของบุคคลอื่น
                          ข้อมูลที่จดทะเบียน ประวัติผู้ใช้ หรือข้อมูลอื่นๆ
                          ในทำนองเดียวกันโดยไม่ชอบด้วยกฎหมายหรือไม่เหมาะสม
                        </li>
                        <li>
                          การกระทำซึ่งเป็นการแทรกแซงเครื่องแม่ข่ายและ/หรือระบบเครือข่ายของการให้บริการฯ
                          การกระทำซึ่งเป็นการใช้บริการฯ ในทางที่ผิดด้วยการใช้บอท (Bots)
                          เป็นเครื่องมือเพื่อโกงหรือด้วยวิธีการทางเทคนิคอื่นๆ
                          การกระทำที่ใช้ข้อบกพร่องของการบริการฯ โดยไตร่ตรองไว้ก่อน
                          การกระทำที่ทำการสอบถามคำถามซ้ำๆ เกินความจำเป็น
                          และการกระทำที่เป็นการแทรกแซงการให้บริการฯ ของ น้องปันอิ่ม
                          หรือการใช้บริการฯ ของผู้ใช้
                        </li>
                        <li>
                          การถอดรหัสซอร์สโค้ดของบริการโดยวิธีวิศวกรรมย้อนกลับ (Reverse engineering)
                          การถอดประกอบหรือสิ่งที่คล้ายคลึงกัน
                          เพื่อวัตถุประสงค์ที่ไม่เหมาะสมหรือในลักษณะที่ไม่เป็นธรรม
                        </li>
                        <li>
                          การกระทำที่ให้ความช่วยเหลือหรือส่งเสริมกิจกรรมใดๆ ที่ระบุไว้ในข้อ 1 ถึงข้อ
                          13 ข้างต้น
                        </li>
                        <li>
                          การกระทำอื่นใด นอกเหนือจากที่ระบุในข้อ 1 ถึงข้อ 14 ที่บริษัทฯ
                          เห็นว่าไม่เหมาะสม
                        </li>
                      </ol>
                    </v-card-text>

                    <v-card-title class="titleHighlight-head">
                      ข้อ 8. ความรับผิดชอบของผู้ใช้</v-card-title
                    >
                    <v-card-text>
                      <ol>
                        <li>
                          ผู้ใช้ต้องใช้บริการฯ นี้ด้วยความเสี่ยงของตน
                          และเป็นผู้รับผิดชอบแต่เพียงผู้เดียวต่อการกระทำทั้งหมดหรือการกระทำใดๆ
                          ที่กระทำไปอันเนื่องมาจากการใช้บริการฯ นี้
                        </li>
                        <li>
                          น้องปันอิ่ม อาจระงับการให้บริการฯ ทั้งหมดหรือแต่บางส่วน
                          ลบบัญชีหรือยกเลิกข้อตกลงใด ๆ เกี่ยวกับการให้บริการระหว่างผู้ใช้และ
                          น้องปันอิ่ม (รวมถึงแต่ไม่จำกัดเพียง ข้อตกลงใด ๆ ตามข้อกำหนดและเงื่อนไขฯ
                          ฉบับนี้) หรือใช้มาตรการอื่นใดที่ น้องปันอิ่ม
                          พิจารณาว่ามีความจำเป็นและเหมาะสมโดยไม่ต้องแจ้งให้ผู้ใช้ทราบล่วงหน้า
                          ในกรณีที่ผู้ใช้รายนั้นกระทำการหรือไม่สามารถกระทำการอย่างหนึ่งอย่างใดดังต่อไปนี้
                          <ol>
                            <li>
                              ผู้ใช้ละเมิดกฎหมายที่เกี่ยวข้อง กฎระเบียบ ข้อกำหนดและเงื่อนไขฯ ฉบับนี้
                              หรือข้อกำหนดและเงื่อนไขฯ เพิ่มเติม
                            </li>
                            <li>
                              ผู้ใช้เป็นสมาชิกของกลุ่มต่อต้านสังคมหรือกลุ่มคนที่มีความเกี่ยวข้องกับกลุ่มดังกล่าว
                            </li>
                            <li>
                              ผู้ใช้ทำให้ น้องปันอิ่ม
                              เสื่อมเสียความน่าเชื่อถือโดยการเผยแพร่ข้อมูลเท็จ ใช้วิธีการโกง
                              หรือวิธีการใดๆ อันไม่ชอบด้วยกฎหมาย
                            </li>
                            <li>
                              ผู้ใช้ถูกร้องเรียน ถูกสั่งห้ามชั่วคราว ถูกขายทอดตลาด
                              ถูกดำเนินคดีล้มละลาย ถูกฟื่นฟูกิจการ
                              หรืออยู่ในกระบวรการซึ่งมีความหมายคล้ายคลึงกัน หรือ
                              ด้วยเหตุผลอื่นใดตามที่ น้องปันอิ่ม
                              เห็นสมควรซึ่งเกี่ยวกับความสามารถในการชำระหนี้ของผู้ใช้
                            </li>
                            <li>
                              น้องปั่นอิ่ม สูญเสียความไว้วางใจต่อผู้ใช้ หรือเมื่อ น้องปันอิ่ม
                              เห็นว่าไม่ควรให้บริการฯ แก่ผู้ใช้ต่อไปด้วยเหตุผลนอกจากที่ได้กล่าวในข้อ
                              (1) ถึงข้อ (4) ข้างต้น
                            </li>
                          </ol>
                        </li>

                        <li>
                          ในกรณีที่เกิดความสูญเสียหรือความเสียหายแก่ น้องปันอิ่ม
                          ไม่ว่าทางตรงหรือทางอ้อม (ซึ่งรวมถึงโดยไม่จำกัดเพียงค่าทนายความ)
                          เนื่องจากการใช้บริการฯ ของผู้ใช้
                          (ซึ่งรวมถึงโดยไม่จำกัดเพียงกรณีที่มีบุคคลภายนอกฟ้องร้องเรียกค่าเสียหายจาก
                          น้องปันอิ่ม เนื่องจากการใช้บริการฯ ดังกล่าว)
                          ผู้ใช้มีหน้าที่ต้องชดใช้ค่าเสียหายให้แก่ น้องปันอิ่ม ทันทีที่ น้องปันอิ่ม
                          ร้องขอ
                        </li>
                      </ol>
                    </v-card-text>

                    <v-card-title class="titleHighlight-head">
                      ข้อ 9. ไม่รับประกันความเสียหาย
                    </v-card-title>
                    <v-card-text v-intersect="onIntersect">
                      <p>
                        น้องปันอิ่ม จะไม่รับประกันใด ๆ
                        ไม่ว่าโดยชัดแจ้งหรือโดยปริยายเกี่ยวกับการให้บริการฯ แก่ผู้ใช้
                        (ซึ่งรวมถึงเนื้อหาหลักฯ) ว่าบริการฯ นั้นปราศจากข้อบกพร่องใดๆ
                        (ข้อบกพร่องในที่นี่รวมถึงแต่ไม่จำกัดเพียง ข้อบกพร่องด้านความปลอดภัย ฯลฯ
                        ข้อผิดพลาด บัค (Bugs) หรือการละเมิดสิทธิใดๆ) หรือมีความปลอดภัย
                        มีความน่าเชื่อถือ มีความถูกต้องสมบูรณ์ มีประสิทธิภาพ
                        และมีความเหมาะสมแก่การใช้ประโยชน์เฉพาะอย่าง ทั้งนี้ น้องปันอิ่ม
                        ไม่มีความรับผิดชอบในการที่จะต้องจัดหาบริการฯ
                        ที่ไม่มีข้อบกพร่องดังกล่าวแต่อย่างใด
                      </p>
                    </v-card-text>
                  </div>
                </div>
              </v-card-text>
            </v-card>
            <v-spacer />
          </v-container>
        </v-card>
        <v-card align="center">
          <v-col cols="8" class="pt-5" align="center" style="margin-top: -12px;">
            <v-btn
              class="accept-consent-button"
              block
              :disabled="!btn_true"
              :style="{
                background: !btn_true ? '#c7c7c7' : '#1E4D94'
              }"
              @click="accept"
            >
              <span :class="!btn_true ? 'grey--text' : 'white--text'">
                ยืนยันเงื่อนไข
              </span>
            </v-btn>
          </v-col>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["value"],
  data() {
    return {
      isIntersecting: false,
      btn_true: false
    };
  },
  mounted() {},
  computed: {
    localDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting;
      if (this.isIntersecting) {
        this.btn_true = true;
      }
    },
    accept() {
      this.$emit("handel-accept");
      this.$emit("input", false);
    }
  }
};
</script>
<style>
.consent-page-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: 0px;
  padding: 0px;
  font-family: Prompt;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: #006b60;
  padding: 0px !important;
}

.header-icon {
  position: absolute;
  right: 0%;
}
.titleHighlight {
  font-family: Prompt;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #333333;
}
.titleHighlight-head {
  padding-top: 0% !important;
  font-size: 16px !important;
  color: #333333 !important;
}
</style>
