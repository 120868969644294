<template>
  <div>
    <!--
    <CardRedeem value="128" button="แลกคูปอง" goto="" />
    !-->

    <v-row style="background-color: #fff">
      <v-col cols="12">
        <v-card elevation="0" outlined>
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="12">
              <v-img :src="coupon.couponDetail.couponImagePath"></v-img>
            </v-col>
          </v-row>
        </v-card>
        <!--
        <v-row class="mt-5">
          <v-col cols="12">
            <span class="font_point">100 </span>
            <span class="font_detail_2">แต้ม </span>
          </v-col>
        </v-row>
        !-->
        <v-row class="mt-0">
          <v-col cols="12">
            <span>ชื่อคูปองที่เปิดรับบริจาค</span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <span class="font_detail_2">{{ coupon.couponName }}</span>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12">
            <span>ราคา</span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <span class="font_detail_2"
              >{{ coupon.couponDetail.couponRule.discountAmount }} บาท</span
            >
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12">
            <span>รายละเอียดคูปอง</span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="font_detail_2" cols="12">
            <span>{{ coupon.couponDetail.couponDetail.couponDescription }}</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="line_hr"> </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col>
            <span>เลือกบุคคลที่ต้องการมอบคูปอง</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <v-card elevation="0" outlined>
              <v-row class="mt-1">
                <v-col>
                  <span class="font_detail_2">จำนวนคูปอง</span>
                </v-col>
              </v-row>
              <v-row class="mt-0 mb-0">
                <v-col>
                  <span class="font_quota mr-2">{{ coupon.couponDetail.collectRemaining }}</span>
                  <span class="font_title"> ใบ</span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-text-field
              v-model="email"
              dense
              outlined
              placeholder="ค้นหาด้วย @one.th"
              required
              :maxlength="50"
              hide-details
              prepend-inner-icon="mdi-magnify"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-btn
              style="color: #1e4d94"
              outlined
              width="110px"
              height="40px"
              @click="addMember"
              :disabled="!email"
            >
              <v-icon size="20">mdi-plus</v-icon>
              <span class="mr-2 font_btn">เพิ่ม</span>
            </v-btn>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="member"
          :items-per-page="5"
          class="elevation-1"
          calculate-widths
          dense
          mobile-breakpoint="320"
        >
          <template #item.carbs="{ item }">
            <div :style="{ color: statusColor(item.email) }">{{ item.email }}</div>
          </template>

          <template v-slot:item.actions="{ item, index }">
            <div class="actionmember">
              <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
              <v-icon small @click="deleteItem(index)"> mdi-delete </v-icon>
            </div>
          </template>
        </v-data-table>
        <v-row>
          <v-col cols="12" align="center">
            <v-btn
              class="font_btn"
              style="color: #1e4d94"
              outlined
              width="94%"
              height="38px"
              @click="clear()"
            >
              <span class="mr-2">ล้าง</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" align="center">
            <v-btn
              class="font_btn"
              style="background: #1e4d94; color: #fff; margin-top: -10px"
              width="94%"
              height="38px"
              @click="submitSendCoupon"
              :disabled="member.length <= 0"
            >
              <!-- @click="sheet = !sheet" -->
              <span class="mr-2">ส่งคูปอง</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-bottom-sheet v-model="sheet">
      <v-sheet class="text-center" height="200px">
        <v-container width="100%" style="background-color: #ffffff; height: 510px">
          <v-row>
            <v-col cols="12">
              <span class="font_title">จำนวนคูปองที่แจก</span>
            </v-col>
            <v-col cols="12" style="margin-top: -10px">
              <span class="font_detail_2" v-if="user">{{ user.email }}</span>
            </v-col>
            <v-col cols="12" align="center" style="margin-top: -10px">
              <v-icon color="primary" :disabled="num == 1" size="37" @click="num_Minus(num)">
                mdi-minus-box-outline</v-icon
              >
              <span class="ml-8 mr-8">{{ num }}</span>
              <v-icon color="primary" size="37" @click="num_Plus(num)">mdi-plus-box-outline</v-icon>
            </v-col>
            <v-col cols="12" style="margin-top: -10px">
              <v-btn
                style="background: #1e4d94; color: #fff"
                width="90%"
                height="40px"
                @click="pushMember"
              >
                <span class="font_btn">ตกลง</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
// import CardRedeem from "../../../components/card/card-redeem.vue";
import { mapState, mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      image: require("assets/image/coupon.png"),
      email: "",
      count: 0,
      checkbox: false,
      num: 1,
      money_set: 50,
      money: 50,
      sheet: false,
      member: [],
      user: {},
      headers: [
        {
          text: "อีเมล",
          align: "start",
          sortable: false,
          value: "email"
        },
        { text: "จำนวนคูปอง", value: "numOfCoupon" },
        { text: "", value: "actions", sortable: false }
      ]
    };
  },
  watch: {},
  computed: {
    ...mapState("project", ["projects"]),
    ...mapState("coupon", ["coupon"]),
    summAll() {
      let sum = 0;
      for (let i in this.member) {
        sum += this.member[i].numOfCoupon;
      }
      return sum;
    }
  },
  mounted() {
    if (!this.coupon) {
      this.$router.push("/manage_coupon");
    }
  },
  methods: {
    ...mapActions("coupon", ["getListRoomByEmail", "sendCoupon"]),
    num_Minus(value) {
      if (value > 1) {
        this.num = value - 1;
      }
    },
    num_Plus(value) {
      if (this.summAll + this.num < parseInt(this.coupon.couponDetail.collectRemaining)) {
        this.num = value + 1;
      } else {
        this.$dialog({
          // title: "Error",
          showCancel: false,
          type: "error",
          desc: `แจกได้ไม่เกิน ${this.coupon.couponDetail.collectRemaining} ใบ`
        });
      }
    },
    async addMember() {
      if (this.summAll + this.num < parseInt(this.coupon.couponDetail.collectRemaining)) {
        this.user = await this.getListRoomByEmail(this.email);
        if (!this.user) {
          this.$dialog({
            // title: "Error",
            showCancel: false,
            type: "error",
            desc: "ไม่พบ email ที่กรอก"
          });
        } else {
          this.sheet = true;
        }
      } else {
        this.$dialog({
          // title: "Error",
          showCancel: false,
          type: "error",
          desc: `แจกได้ไม่เกิน ${this.coupon.couponDetail.collectRemaining} ใบ`
        });
      }
    },
    pushMember() {
      for (let index in this.member) {
        if (this.user.email == this.member[index].email) {
          this.member[index].numOfCoupon = this.num;
          this.sheet = false;
          return true;
        }
      }
      this.member.push({ ...this.user, numOfCoupon: this.num });
      this.sheet = false;
      this.email = "";
      this.num = 1;
    },
    editItem(item) {
      this.sheet = true;
      this.user = item;
      this.num = item.numOfCoupon;
    },
    deleteItem(index) {
      this.member.splice(index, 1);
    },
    clear() {
      this.member = [];
    },
    submitSendCoupon() {
      let body = {
        agencyProjectId: this.projects[0].id,
        couponId: this.coupon.couponId,
        couponName: this.coupon.couponName,
        amount: 1,
        users: [...this.member]
      };
      this.sendCoupon(body).then(() => {
        this.$dialog({
          // title: "Error",
          showCancel: false,
          type: "success",
          desc: `แจกคูปองสำเร็จ`,
          okLabel: "ตกลง"
        }).then(() => {
          this.member = [];
          this.$router.replace("/manage_coupon");
        });
      });
      console.log(body);
    }
  }
};
</script>

<style scoped>
.tag_detail {
  width: 0;
  height: 0;
  border-left: 0px solid #d32f2f;
  border-right: 100px solid transparent;
  border-bottom: 0px solid transparent;
  border-top: 100px solid #d32f2f;
  position: absolute;
  z-index: 2;
}
.font_detail_2 {
  color: #aeaeae;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}
.save-btn-div {
  position: fixed;
  bottom: 0%;
  left: 0%;
  width: 100%;
  height: 180px;
  align-items: center;
  box-shadow: 2px 0px 4px rgba(199, 199, 199, 0.4);
  z-index: 1;
  background-color: #ffffff;
}
.font_money {
  color: #fff;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.font_type {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  position: absolute;
  z-index: 2;
  margin-top: -70px;
}
.font_title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #aeaeae;
}
.font_quota {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;
  color: #1e4d94;
}
.font_btn_change {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #1e4d94;
}
.font_point {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  line-height: 18px;
  color: #1e4d94;
}
.line_hr {
  margin-left: 3%;
  margin-right: 3%;
  border-bottom: 2px solid #e0e0e0;
}
.font_btn {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.actionmember {
  display: flex;
}
</style>
