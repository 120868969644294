<template>
  <div>
    <CardRedeem value="128" button="" />

    <v-row no-gutters class="mt-7 mx-n6">
      <v-col>
        <v-tabs fixed-tabs v-model="select_type" center-active centered>
          <v-tab v-for="(packages_tab, index) in list" :key="index">
            <span :style="select_type == packages_tab.value ? 'color:#1E4D94' : 'color:grey'">{{
              packages_tab.title
            }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="select_type" style="background-color: #f5f5f5" class="mb-6">
          <v-tab-item class="bg-type mt-0 pt-7 px-7">
            <v-row align="center" justify="center" v-for="(item, index) in coupons" :key="index">
              <v-col cols="12">
                <v-card class="card_coupon">
                  <v-row justify="center" align="center" no-gutters>
                    <v-col cols="12">
                      <v-col cols="12">
                        <!--
                        <div class="tag">
                          <v-row no-gutters>
                            <v-col cols="12" class="ml-1">
                              <span class="font_type">coupon</span>
                            </v-col>
                          </v-row>
                        </div>
                        !-->
                        <v-img
                          :src="
                            item.couponDetail.couponImagePath
                              ? item.couponDetail.couponImagePath
                              : image
                          "
                        ></v-img>
                      </v-col>
                      <!--
                      <v-row no-gutters style="margin-top: -15px">
                        <v-col cols="12" align="center">
                          <span class="font_title">คูปองร่วมบริจาคอาหาร</span>
                        </v-col>
                      </v-row>
                      !-->
                      <v-row justify="center" no-gutters class="mt-4">
                        <v-col cols="12" class="line"></v-col
                      ></v-row>
                      <v-col cols="12" class="mt-2 ml-2">
                        <span>{{ item.couponName }}</span>
                      </v-col>
                      <v-col cols="12" class="ml-2" style="margin-top: -15px">
                        <span class="font_detail">{{
                          item.couponDetail.couponDetail.couponDescription
                        }}</span>
                      </v-col>

                      <v-row class="mb-2 ml-2 mr-2">
                        <v-col cols="4" class="mt-1" align="center">
                          <span class="point_redeem">{{ item.price }}</span>
                          <span class="font_point"> แต้ม</span>
                        </v-col>
                        <v-col cols="8" align="center">
                          <v-btn
                            color="primary"
                            outlined
                            width="100%"
                            height="40px"
                            @click="goTo(item)"
                            :disabled="item.price > quataPoint.quotaPoint"
                          >
                            <span v-if="item.price > quataPoint.quotaPoint">แลก</span>
                            <span v-else class="btn_font_redeem">แลก</span>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item class="bg-type mt-0">
            <v-data-table
              :headers="headers"
              :items="quataPointHistory"
              :items-per-page="5"
              class="elevation-1"
              calculate-widths
              dense
              mobile-breakpoint="320"
            >
              <template #item.carbs="{ item }">
                <div :style="{ color: statusColor(item) }">
                  {{ item.pointAfter - item.pointBefore }}
                </div>
              </template>
              <template #item.createTimeFormat="{ item }">
                <div>{{ dateTimeFormat(item.createTime) }}</div>
              </template>
              <template #item.activity="{ item }">
                <div>{{ item.isincrease ? "คืนแต้ม" : "แลกคูปอง" }}</div>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardRedeem from "../../../components/card/card-redeem.vue";
import { mapState, mapActions } from "vuex";
import moment from "moment";
export default {
  components: { CardRedeem },
  data() {
    return {
      image: require("assets/image/coupon.png"),
      card_for: 5,
      select_type: 0,
      list: [
        {
          title: "แลกคูปอง",
          counter_visible: true,
          value: 0
        },
        {
          title: "ประวัติ",
          counter_visible: true,
          value: 1
        }
      ],

      headers: [
        {
          text: "วันที่",
          align: "start",
          sortable: false,
          value: "createTimeFormat"
        },
        { text: "เลขที่งาน", value: "orderId" },
        { text: "กิจกรรม", value: "activity" },
        { text: "แต้ม", value: "carbs" }
      ],
      desserts: [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: "1%"
        },
        {
          name: "Ice cream sandwich",
          calories: 237,
          fat: 9.0,
          carbs: -10,
          protein: 4.3,
          iron: "1%"
        }
      ]
    };
  },
  methods: {
    ...mapActions("project", ["getProjects"]),
    ...mapActions("coupon", [
      "getCoupons",
      "selectCoupon",
      "getQuotapoint",
      "getQuotapointHistory"
    ]),
    goTo(val) {
      this.selectCoupon(val);
      this.$router.push("/redeem_detail");
    },
    statusColor(val) {
      if (val.pointAfter - val.pointBefore > 0) {
        return "#70BE45";
      } else {
        return "#D40000";
      }
    },
    dateTimeFormat(val) {
      return val ? moment(val).format("DD/MM/YY HH:mm") : "-";
    }
  },
  computed: {
    ...mapState("project", ["projects"]),
    ...mapState("coupon", ["coupons", "quataPoint", "quataPointHistory"])
  },
  async mounted() {
    await this.getProjects();
    if (this.projects.length > 0) {
      this.getCoupons(this.projects[0].id);
      this.getQuotapoint(this.projects[0].id);
      this.getQuotapointHistory(this.projects[0].id);
    }
  }
};
</script>

<style scoped>
.point_redeem {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #1e4d94;
}
.btn_font_redeem {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 27px;
  color: #1e4d94;
}
.font_point {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 27px;
  color: #aeaeae;
}
.card_coupon {
  border-radius: 4px !important;
  background-color: #fff;
}
.tag {
  width: 0;
  height: 0;
  border-left: 0px solid #d32f2f;
  border-right: 80px solid transparent;
  border-bottom: 0px solid transparent;
  border-top: 80px solid #d32f2f;
  position: absolute;
  z-index: 2;
}
.font_type {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  position: absolute;
  z-index: 2;
  margin-top: -70px;
}
</style>
