import { GET_API, GET_AGENCIES_SUCCESS, GET_AGENCY_SUCCESS, GET_FAILURE } from "./constant";

export default {
  [GET_API]: state => {
    state.isFetching = true;
    state.error = null;
  },
  [GET_AGENCIES_SUCCESS]: (state, payload) => {
    state.isFetching = false;
    state.error = null;
    state.agencies = payload;
  },
  [GET_AGENCY_SUCCESS]: (state, payload) => {
    state.isFetching = false;
    state.error = null;
    state.agency = payload;
  },
  [GET_AGENCY_SUCCESS]: (state, payload) => {
    state.isFetching = false;
    state.error = null;
    state.agency = payload;
  },
  [GET_FAILURE]: (state, payload) => {
    state.isFetching = false;
    state.error = payload;
  }
};
