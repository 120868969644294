<template style="background-color: #fff;">
  <v-row class="mx-n6 mt-n6" style="background-color: #fff;">
    <v-col cols="12 ">
      <v-row>
        <v-col align="center">
          <span class="font_title">โควตามูลค่าคูปองคงเหลือ</span>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-3">
        <v-col align="center">
          <span class="font_quota mr-2">{{ quataPoint.quotaPoint }}</span>
          <span class="font_title"> แต้ม</span>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4">
        <v-col align="center" v-show="button != ''">
          <v-btn rounded color="primary" outlined width="110px" height="25px" @click="goTo(goto)">
            <span class="ml-2 font_btn_change">{{ button }}</span>
            <v-icon size="20" class="mt-1">mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["value", "button", "goto"],
  computed: {
    ...mapState("coupon", ["quataPoint"]),
    localImage() {
      console.log(this.image);
      const baseUrl = process.env.BASE_URL_API;
      const image = require("assets/image/preview.png");
      return this.image ? `${baseUrl}/spring/api/media/image/${this.image}` : image;
    },
    localDesc() {
      return this.title && this.title.length > 40
        ? `${this.title.substring(0, 40)}...`
        : this.title;
    }
  },
  methods: {
    goTo(val) {
      console.log(val);
      this.$router.push("/redeem_select");
    }
  }
};
</script>

<style scoped>
.font_title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #aeaeae;
}
.font_quota {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;
  color: #1e4d94;
}
</style>
