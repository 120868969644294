import actions from "./actions";
import mutations from "./mutations";

const state = {
  coupons: [],
  coupon: null,
  quataPoint: {},
  quataPointHistory: [],
  donateAmount: [],
  couponAmount: []
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
