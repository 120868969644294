var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CardRedeem',{attrs:{"value":"128","button":""}}),_c('v-row',{staticClass:"mt-7 mx-n6",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-tabs',{attrs:{"fixed-tabs":"","center-active":"","centered":""},model:{value:(_vm.select_type),callback:function ($$v) {_vm.select_type=$$v},expression:"select_type"}},_vm._l((_vm.list),function(packages_tab,index){return _c('v-tab',{key:index},[_c('span',{style:(_vm.select_type == packages_tab.value ? 'color:#1E4D94' : 'color:grey')},[_vm._v(_vm._s(packages_tab.title))])])}),1),_c('v-tabs-items',{staticClass:"mb-6",staticStyle:{"background-color":"#f5f5f5"},model:{value:(_vm.select_type),callback:function ($$v) {_vm.select_type=$$v},expression:"select_type"}},[_c('v-tab-item',{staticClass:"bg-type mt-0 pt-7 px-7"},_vm._l((_vm.coupons),function(item,index){return _c('v-row',{key:index,attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"card_coupon"},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{attrs:{"src":item.couponDetail.couponImagePath
                            ? item.couponDetail.couponImagePath
                            : _vm.image}})],1),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{staticClass:"line",attrs:{"cols":"12"}})],1),_c('v-col',{staticClass:"mt-2 ml-2",attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(item.couponName))])]),_c('v-col',{staticClass:"ml-2",staticStyle:{"margin-top":"-15px"},attrs:{"cols":"12"}},[_c('span',{staticClass:"font_detail"},[_vm._v(_vm._s(item.couponDetail.couponDetail.couponDescription))])]),_c('v-row',{staticClass:"mb-2 ml-2 mr-2"},[_c('v-col',{staticClass:"mt-1",attrs:{"cols":"4","align":"center"}},[_c('span',{staticClass:"point_redeem"},[_vm._v(_vm._s(item.price))]),_c('span',{staticClass:"font_point"},[_vm._v(" แต้ม")])]),_c('v-col',{attrs:{"cols":"8","align":"center"}},[_c('v-btn',{attrs:{"color":"primary","outlined":"","width":"100%","height":"40px","disabled":item.price > _vm.quataPoint.quotaPoint},on:{"click":function($event){return _vm.goTo(item)}}},[(item.price > _vm.quataPoint.quotaPoint)?_c('span',[_vm._v("แลก")]):_c('span',{staticClass:"btn_font_redeem"},[_vm._v("แลก")])])],1)],1)],1)],1)],1)],1)],1)}),1),_c('v-tab-item',{staticClass:"bg-type mt-0"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.quataPointHistory,"items-per-page":5,"calculate-widths":"","dense":"","mobile-breakpoint":"320"},scopedSlots:_vm._u([{key:"item.carbs",fn:function(ref){
                            var item = ref.item;
return [_c('div',{style:({ color: _vm.statusColor(item) })},[_vm._v(" "+_vm._s(item.pointAfter - item.pointBefore)+" ")])]}},{key:"item.createTimeFormat",fn:function(ref){
                            var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.dateTimeFormat(item.createTime)))])]}},{key:"item.activity",fn:function(ref){
                            var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.isincrease ? "คืนแต้ม" : "แลกคูปอง"))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }