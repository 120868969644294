// import Home from "views/home/index.vue";
import Foundation from "views/foundation/index.vue";
import Donation from "views/donation/index.vue";
import History from "views/history";
import Project from "views/project";
import ProjectLayout from "views/project/detail";
import ProjectDetail from "views/project/detail/detail.vue";
import ProjectTransfer from "views/project/detail/transfer.vue";
import home_isolation from "views/home_isolation";
import isolation_select from "views/home_isolation/modules/isolation_select";
import isolation_detail from "views/home_isolation/modules/isolation_detail";
import home_isolation_approved from "views/home_isolation/modules/isolation_approved"

import detail_donate from "views/detail_donate";
import detail from "views/detail_donate/modules/detail";

import manage_coupon from "views/manage_coupon";
import manage_detail from "views/manage_coupon/modules/detail";
import manage_detail_donate_amount from "views/manage_coupon/modules/donate_amount";
import manage_detail_coupon_amount from "views/manage_coupon/modules/coupon_amount";

import redeem_coupon from "views/redeem_coupon";
import redeem_select from "views/redeem_coupon/modules/redeem_select";
import redeem_detail from "views/redeem_coupon/modules/redeem_detail";

const routes = [
  // {
  //   path: "/",
  //   name: "home",
  //   component: Home,
  //   meta: { background: "#f5f5f5" }
  // },
  {
    path: "/foundation/:id",
    name: "foundation",
    component: Foundation
  },
  {
    path: "/donation/:id",
    name: "donation",
    component: Donation
  },
  {
    path: "/history",
    name: "history",
    component: History,
    meta: { background: "#f5f5f5", title: "ประวัติแต้ม" }
  },
  {
    path: "/project",
    name: "project",
    component: Project,
    meta: { background: "#f5f5f5", title: "โครงการทั้งหมด" }
  },
  {
    path: "/project/:id",
    name: "project-layout",
    component: ProjectLayout,
    meta: { background: "#f5f5f5" },
    children: [
      {
        path: "",
        name: "project-detail",
        component: ProjectDetail,
        meta: { background: "#f5f5f5" }
      },
      {
        path: "transfer",
        name: "project-transfer",
        component: ProjectTransfer,
        meta: { background: "#f5f5f5" }
      }
    ]
  },
  {
    path: "/home_isolation",
    name: "home isolation",
    component: home_isolation,
    meta: { background: "#f5f5f5", layout: "basic-layout" }
    // meta: { background: "#f5f5f5", title: "โครงการทั้งหมด" }
  },
  {
    path: "/home_isolation/:id",
    name: "isolation_select",
    component: isolation_select,
    meta: { background: "#f5f5f5", title: "หน้าเลือกโรงพยาบาล" , title_hospital_by:""}
  },
  {
    path: "/home_isolation/:ets/:id",
    name: "isolation_deatil",
    component: isolation_detail,
    meta: { background: "#f5f5f5", title: "เลือกมื้ออาหาร", title_select_by:""}
  },
    {
    path: "/approved",
    name: "home_isolation_approved",
    component: home_isolation_approved,
    meta: { background: "#f5f5f5", layout: "basic-layout"}
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   component: About,
  //   meta: { layout: "basic-layout" }
  // }
  {
    path: "/detail_donate",
    name: "detail_donate",
    component: detail_donate,
    meta: { background: "#f5f5f5", title: "เลือกคูปอง" }
  },
  {
    path: "/detail",
    name: "detail",
    component: detail,
    meta: { background: "#f5f5f5", title: "ร่วมบริจาค" }
  },

  {
    path: "/manage_coupon",
    name: "manage_coupon",
    component: manage_coupon,
    meta: { background: "#f5f5f5", title: "จัดการคูปอง" }
  },
  {
    path: "/manage_detail",
    name: "manage_detail",
    component: manage_detail,
    meta: { background: "#f5f5f5", title: "แจกคูปอง" }
  },
  {
    path: "/manage_detail/donate_amount/:id/:agency",
    name: "manage_detail_donate_amount",
    component: manage_detail_donate_amount,
    meta: { background: "#f5f5f5", title: "ยอดบริจาค" }
  },
  {
    path: "/manage_detail/coupon_amount/:id/:agency",
    name: "manage_detail_coupon_amount",
    component: manage_detail_coupon_amount,
    meta: { background: "#f5f5f5", title: "ยอดคงเหลือ" }
  },

  // *****#-redeem-#*****
  {
    path: "/redeem_coupon",
    name: "redeem_coupon",
    component: redeem_coupon,
    meta: { background: "#f5f5f5", title: "แลกคูปอง" }
  },
  {
    path: "/redeem_select",
    name: "redeem_select",
    component: redeem_select,
    meta: { background: "#f5f5f5", title: "แลกคูปอง" }
  },
  {
    path: "/redeem_detail",
    name: "redeem_detail",
    component: redeem_detail,
    meta: { background: "#f5f5f5", title: "รายละเอียด" }
  }
];

export default routes;
