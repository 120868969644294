<template>
  <div>
    <v-row class="mt-2 mb-3" align="center" justify="center">
      <v-card class="py-2" width="100%" outlined style="border: none">
        <div style="display: flex;">
          <v-col cols="1"></v-col>
          <v-col cols="6 pl-0" align="center" align-self="center">
            <div class="filter-date">
              <!-- <i-date-range v-model="dateRange" label="วันที่" hide-details></i-date-range> -->
              <i-date-range v-model="dateRange" label="ช่วงเวลา" hide-details></i-date-range>
              <!-- <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                left
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    width="80%"
                    v-model="date"
                    label="วันที่"
                    append-icon="mdi-calendar-week"
                    class="i-text-field"
                    readonly
                    outlined
                    hide-details
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" @input="menu = false" no-title scrollable>
                  
                </v-date-picker>
              </v-menu> -->
            </div>
          </v-col>
          <v-col cols="5 px-0" align="center" align-self="center">
            <v-btn
              outlined
              color="#1E4D94"
              style="text-transform: none"
              :href="`${urlExport}/${this.$route.params.id}?start=${dateRange[0]}&end=${dateRange[1]}`"
              :disabled="dateRange.length<2"
              >Export file</v-btn
            >
          </v-col>
        </div>
      </v-card>
    </v-row>

    <v-row class="ma-0 pt-7">
      <v-col cols="mt-7 pa-0"></v-col>
      <v-col cols="12 pa-0" v-for="item in items" :key="item.key">
        <v-card
          class="mx-auto mb-7"
          width="90%"
          outlined
          @click="selectItem(item.key)"
          style="box-shadow: 2px 2px #d4d4d4"
        >
          <v-list-item three-line align="center">
            <!-- <v-list-item-title class="text-h5 mb-1"> </v-list-item-title> -->
            <v-list-item-subtitle class="card-title-hi">{{ item.value }} </v-list-item-subtitle>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      dateRange: [],
      items: [
        {
          key: "braekfast",
          value: "มื้อเช้า"
        },
        {
          key: "lunch",
          value: "มื้อเที่ยง"
        },
        {
          key: "dinner",
          value: "มื้อเย็น"
        }
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      urlExport: `${process.env.VUE_APP_BASE_URL_API}/coupon/homeiexportexcel`
    };
  },
  async mounted() {
    this.id = this.$route.params.id;
  },
  methods: {
    selectItem(v){
      this.$router.push({
        name: "isolation_deatil",
        params: {
          ets: v,
          id: this.id
        },
      })
      // this.$router.push(`/home_isolation/${v}/${this.id}`)
      if(v == 'braekfast'){
        this.$route.meta.title_select_by = "/ มื้อเช้า"
      }else if(v == 'lunch'){
        this.$route.meta.title_select_by = "/ มื้อเที่ยง"
      }
      else if(v == 'dinner'){
        this.$route.meta.title_select_by = "/ มื้อเย็น"
      }
    }
  }
};
</script>

<style scoped>
.main-content {
  padding: 0% !important;
}
.card-title-hi {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #333333 !important;
}
</style>
