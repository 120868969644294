import {
  GET_USER_POINT,
  GET_USER_POINT_SUCCESS,
  GET_USER_POINT_FAILURE,
  GET_USER_ACCOUNT,
  GET_USER_ACCOUNT_SUCCESS,
  GET_USER_ACCOUNT_FAILURE
} from "./constant";

import { getUserPoint, getUserAcount, donate } from "services/user.service";

export default {
  getPoint: async ({ commit }) => {
    try {
      commit(GET_USER_POINT);
      const result = await getUserPoint();
      commit(GET_USER_POINT_SUCCESS, result);
    } catch (error) {
      commit(GET_USER_POINT_FAILURE, error.message);
    }
  },

  getAcount: async ({ commit }) => {
    try {
      commit(GET_USER_ACCOUNT);
      const result = await getUserAcount();
      commit(GET_USER_ACCOUNT_SUCCESS, result);
    } catch (error) {
      commit(GET_USER_ACCOUNT_FAILURE, error.message);
    }
  },

  donate: async ({ commit }, payload) => {
    try {
      await donate(payload);
    } catch (error) {
      commit(GET_USER_ACCOUNT_FAILURE, error.message);
    }
  }
};
