<template>
  <div>
    <div class="project-detail">
      <i-card outlined>
        <div>
          <div class="project-group-content">
            <div class="project-title">ชื่อโครงการ</div>
            <div class="project-sub-title">
              {{ project.name }}
            </div>
          </div>
          <div class="project-group-content">
            <div class="project-title">ชื่อหน่วยงาน</div>
            <div class="project-sub-title">
              {{ project.agencyName }}
            </div>
          </div>
          <div class="project-group-content">
            <div class="project-title">วันที่สิ้นสุดกิจกรรม</div>
            <div class="project-sub-title">{{ datePreview }}</div>
          </div>
          <div class="project-group-content">
            <div class="project-title">รายละเอียดการร่วมบริจาค</div>
            <div class="project-sub-title">
              {{ project.details }}
            </div>
          </div>

          <!-- <div class="project-group-content">
            <div class="project-title">สถานที่ร่วมกิจกรรม</div>
            <div class="project-sub-title">
              {{ project.location }}
            </div>
          </div> -->
        </div>
      </i-card>
    </div>
    <div class="detail-action">
      <i-button style="height: 45px" @click="handleClick">โอนแต้ม</i-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  data: () => ({
    image: require("assets/image/preview.png"),
    checkbox: null,
    id: null
  }),
  async mounted() {
    // await this.getProject(this.$route.params.id);
    this.id = this.$route.params.id;
  },
  computed: {
    ...mapState("project", ["isFetching", "project", "agencyPoint"]),
    datePreview() {
      return this.localStartDate + `${this.localEndDate ? ` ถึง ${this.localEndDate}` : ""}`;
    },
    localStartDate() {
      return this.project.startTime ? moment(this.project.startTime).format("DD/MM/YYYY") : "";
    },
    localEndDate() {
      return this.project.endTime
        ? moment(this.project.endTime).format("DD/MM/YYYY")
        : "ไม่สิ้นสุด";
    }
  },
  methods: {
    // ...mapActions("project", ["getProject"]),
    async handleClick() {
      this.$router.push(`/project/${this.id}/transfer`);
    }
  }
};
</script>

<style></style>
