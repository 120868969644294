var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('i-text-field',{attrs:{"label":"ค้นหารายชื่อโครงการ"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"home-title"},[_vm._v("หน่วยงานและมูลนิธิ")]),_c('card-list',{attrs:{"items":_vm.projects},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var id = ref.id;
var name = ref.name;
var image = ref.image;
var details = ref.details;
var endTime = ref.endTime;
var amount = ref.amount;
var total = ref.total;
return [_c('i-card',{staticStyle:{"padding":"0"}},[_c('div',{staticClass:"project-card-container"},[_c('div',{staticClass:"project-content"},[_c('div',{staticClass:"project-content-top"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":(_vm.baseUrl + "/media/image" + image),"alt":"image"}})]),_c('div',{staticClass:"content",on:{"click":function($event){return _vm.$router.push(("/project/" + id))}}},[_c('div',[_c('div',{staticClass:"card-title"},[_vm._v(" "+_vm._s(name)+" ")]),_c('div',{staticClass:"card-desc"},[_vm._v(" "+_vm._s(details)+" ")])]),_c('div',{staticClass:"end-date"},[_vm._v("วันที่สิ้นสุด: "+_vm._s(_vm.dateFormat(endTime)))])])]),_c('div',{staticClass:"project-content-bottom"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"point-title"},[_vm._v(" ยอดแต้มสะสม ")]),_c('div',{staticClass:"point-value"},[_vm._v(_vm._s(total)+" แต้ม")])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"point-title"},[_vm._v(" ยอดแต้มคงเหลือ ")]),_c('div',{staticClass:"point-value"},[_vm._v(_vm._s(amount)+" แต้ม")])])])]),_c('div',{staticClass:"project-action",on:{"click":function($event){return _vm.transferClick(id)}}},[_vm._v(" โอนแต้ม ")])])]),_c('br')]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }