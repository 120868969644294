<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <div class="donation-title">
        จำนวนที่ต้องการบริจาค
      </div>
      <i-text-field
        v-model="amount"
        label="ระบุจำนวนที่ต้องการบริจาค"
        type="number"
        :rules="amountRules"
        required
      ></i-text-field>
      <div class="donation-title">ลงนามบริจาค</div>
      <i-text-field
        v-model="name"
        label="ระบุชื่อผู้บริจาค"
        :rules="nameRules"
        required
        :disabled="checkbox"
      ></i-text-field>
      <v-checkbox
        class="i-checkbox"
        v-model="checkbox"
        label="ไม่ประสงค์ลงนาม"
        style="margin-top: 0"
      ></v-checkbox>
      <div class="detail-action">
        <i-button style="height: 45px" @click="handleClick">บริจาค</i-button>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data: () => ({
    valid: true,
    name: null,
    amount: null,
    checkbox: false
  }),
  async mounted() {
    this.getPoint();
    await this.getAcount();
    this.name = `${this.userAccount.account_title_th}${this.userAccount.first_name_th} ${this.userAccount.last_name_th}`;
  },
  computed: {
    ...mapState("user", ["userAccount", "userPoint"]),
    ...mapState("agency", ["agency"]),
    amountRules() {
      // return this.userPoint.points;
      console.log(this.userPoint.points);
      return [
        v => !!v || "กรุณากรอกจำนวน",
        v => v > 0 || "กรุณากรอกจำนวนมากกว่า 0 แต้ม",
        v => {
          if (Number(v) > Number(this.userPoint.points)) {
            return "แต้มของคุณไม่เพียงพอ";
          }
          return true;
        }
        //   v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ];
    },
    nameRules() {
      if (this.checkbox) {
        return [];
      }
      return [
        v => !!v || "กรุณากรอกชื่อ"
        //   v => (v && v.length <= 10) || "Name must be less than 10 characters"
      ];
    }
  },
  methods: {
    ...mapActions("user", ["getAcount", "getPoint", "donate"]),
    async handleClick() {
      if (this.$refs.form.validate()) {
        const body = {
          agencyId: this.agency.id,
          donorId: localStorage.getItem("account_id"),
          donorName: this.checkbox ? "" : this.name,
          point: this.amount,
          showDonorName: this.checkbox
        };

        console.log(body);

        await this.donate(body);

        this.$dialog({
          showCancel: false,
          okLabel: "กลับหน้าหลัก",
          image: require("assets/thank-icon.svg"),
          desc: "ขอบคุณที่ร่วมบริจาคในครั้งนี้"
        }).then(() => {
          this.$router.push("/");
        });
      }
    }
  }
};
</script>

<style>
.donation-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  margin-bottom: 10px;
}

.i-checkbox.v-input--checkbox .v-input__slot .v-icon {
  color: #c7c7c7;
}
.i-checkbox.v-input--checkbox .v-input__slot .v-label {
  color: #c7c7c7;
}
</style>
