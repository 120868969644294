import axios from "clients/axios";

export const getUserPoint = async () => {
  try {
    const res = await axios.get("point/getPoint");
    const { data } = res;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getUserAcount = async () => {
  try {
    const res = await axios.get("account");
    const { data } = res;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const donate = async payload => {
  try {
    const res = await axios.post("agency/donate", payload);
    const { data } = res;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
