<template>
  <div>
    <v-row justify="center" align="center" style="background-color: #fff">
      <v-col cols="12" class="text-center">
        ประวัติการบริจาค
      </v-col>
      <v-data-table
        :headers="headers"
        :items="donateAmount"
        :items-per-page="5"
        class="elevation-1 px-10"
      >
        <template #item.createTime="{ item }">
          <div>{{ dateTimeFormat(item.createTime) }}</div>
        </template>
      </v-data-table>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {},
  data() {
    return {
      data: {
        couponId: null,
        agencyProjectId: null
      },
      headers: [
        {
          text: "ชื่อคูปอง",
          align: "start",
          sortable: false,
          value: "couponName"
        },
        { text: "ผู้บริจาค", value: "donorName" },
        { text: "จำนวนคูปอง", value: "numOfCoupon" },
        { text: "ราคา", value: "price" },
        { text: "วันเวลา", value: "createTime" }
      ]
    };
  },
  watch: {},
  computed: {
    ...mapState("coupon", ["couponAmount", "donateAmount"])
  },
  async mounted() {
    this.data.couponId = await this.$route.params.id;
    this.data.agencyProjectId = await this.$route.params.agency;

    this.donateAmountDetail();
  },
  methods: {
    ...mapActions("coupon", ["getCouponAmount", "getDonateAmount"]),
    async donateAmountDetail() {
      await this.getDonateAmount(this.data);
    },
    dateTimeFormat(val) {
      return val ? moment(val).format("DD/MM/YY HH:mm") : "-";
    }
  }
};
</script>
