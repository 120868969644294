<template>
  <div>
    <div class="home-title">ชื่อโครงการ</div>
    <i-select-ss
      v-model="projectSelected"
      label="เลือกโครงการ"
      url="/agency/getProjectApprove"
      item-text="name"
      item-value="id"
      hide-details
      return-object
    ></i-select-ss>
    <div class="filter-container">
      <div class="filter-type">
        <div class="home-title">ประเภท</div>
        <i-select
          v-model="typeSelected"
          label="รับแต้ม-โอนแต้ม"
          item-value="key"
          :items="typeItems"
          hide-details
        ></i-select>
      </div>
      <div class="filter-date">
        <div class="home-title">ช่วงเวลา</div>
        <i-date-range v-model="dateRange" label="ช่วงเวลา" hide-details></i-date-range>
      </div>
    </div>
    <div class="list-header">
      <div class="home-title">รายการล่าสุด</div>
      <div v-if="projectSelected">
        <i-button
          :href="
            `${urlExport}?agencyId=${projectSelected.id}&type=${typeSelected}&startDate=${startDate}&endDate=${endDate}`
          "
          >Export file</i-button
        >
      </div>
    </div>
    <!-- {{ history }} -->
    <card-list :items="history">
      <template v-slot:item="{ point, createTime, type, donorName, showDonorName }">
        <i-card>
          <div class="history-card">
            <div class="history-card-image">
              <img v-if="type == 'in'" :src="money_in" alt="image" />
              <img v-else :src="money_out" alt="image" />
            </div>
            <div class="history-card-detail">
              <div class="history-card-title">
                {{ projectSelected.name }}
              </div>
              <div class="history-card-date">
                <div class="date-text">ชื่อผู้โอน</div>
                <div class="date-value">
                  <div v-if="type == 'in'">{{ donorName }}</div>
                  <div v-else-if="(type == 'in') & (showDonorName == true)">ไม่ประสงค์ออกนาม</div>
                  <div v-else>{{ projectSelected.name }}</div>
                </div>
              </div>
              <div class="history-card-date">
                <div class="date-text">ชื่อผู้รับ</div>
                <div class="date-value">
                  <div v-if="type != 'in'">{{ donorName }}</div>
                  <div v-else>{{ projectSelected.name }}</div>
                </div>
              </div>
              <div class="history-card-date">
                <div class="date-text">วันที่{{ type == "in" ? "รับ" : "โอน" }}</div>
                <div class="date-value">{{ dataTimeFormat(createTime) }} น.</div>
              </div>
            </div>
            <div class="history-card-point">
              <div class="home-title">{{ typeResolver(type) }}</div>
              <div v-show="type == 'in'" style="color: #58A144;">
                {{ point ? point.toLocaleString("th-TH") : 0 }} แต้ม
              </div>
              <div v-show="type == 'out'" style="color: #D1392B;">
                {{ point ? point.toLocaleString("th-TH") : 0 }} แต้ม
              </div>
            </div>
          </div>
        </i-card>
        <br />
      </template>
    </card-list>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CardList from "components/card/card-list.vue";
// import image from "assets/coin.svg";
import money_in from "assets/money_in.svg";
import money_out from "assets/money_out.svg";
import moment from "moment";

export default {
  components: {
    CardList
  },
  data: () => ({
    projectSelected: null,
    typeSelected: "all",
    dateRange: [],
    money_in,
    money_out,
    typeItems: [
      {
        key: "all",
        text: "รับแต้ม-โอนแต้ม"
      },
      {
        key: "in",
        text: "รับแต้ม"
      },
      {
        key: "out",
        text: "โอนแต้ม"
      }
    ],
    urlExport: `${process.env.VUE_APP_BASE_URL_API}/donate/exportdonatehistory`,
    startDate: null,
    endDate: null
  }),
  watch: {
    async projectSelected(val) {
      let dateRange = this.dateRange.reduce((res, item, i) => {
        if (i === 0) {
          return { startDate: this.startDate };
        } else if (i === 1) {
          return { ...res, endDate: item };
        }
        return res;
      }, {});
      const type = this.typeSelected === "in-out" ? null : this.typeSelected;
      await this.getHistory({
        agencyId: val.id,
        type,
        ...dateRange,
        startDate: this.startDate,
        endDate: this.endDate
      });
    },
    async typeSelected(val) {
      const type = val === "in-out" ? null : val;
      await this.getHistory({
        agencyId: this.projectSelected.id,
        type,
        startDate: this.startDate,
        endDate: this.endDate
      });
    },
    async dateRange() {
      if (this.dateRange.length == 2 && this.dateRange[0] > this.dateRange[1]) {
        let tmp = this.dateRange[0];
        this.dateRange[0] = this.dateRange[1];
        this.dateRange[1] = tmp;
      }
      this.startDate = this.dateRange[0] ? this.dateRange[0] : null;
      this.endDate = this.dateRange[1] ? this.dateRange[1] : null;

      const type = this.typeSelected === "in-out" ? null : this.typeSelected;
      await this.getHistory({
        agencyId: this.projectSelected.id,
        type,
        startDate: this.startDate,
        endDate: this.endDate
      });
    }
  },
  computed: {
    ...mapState("history", ["history"])
  },
  methods: {
    ...mapActions("history", ["getHistory"]),
    dataTimeFormat(date) {
      return date ? moment(date).format("DD/MM/YYYY HH:mm") : "";
    },
    typeResolver(type) {
      if (type === "in") {
        return "รับแต้ม";
      }
      return "โอนแต้ม";
    }
  }
};
</script>

<style scoped>
.filter-container {
  display: flex;
  margin: 12px 0;
}
.filter-type {
  flex: 1;
  padding-right: 10px;
}
.filter-date {
  flex: 1;
  padding-left: 10px;
}
.list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 5px 0; */
  padding: 12px 0;
}
.history-card {
  display: flex;
}
.history-card-image {
  height: 56px;
  width: 56px;
  align-self: center;
  /* flex: 1; */
}
.history-card-detail {
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin: 0 12px; */
  padding: 0 12px;
}
.history-card-point {
  flex: 2;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}
.history-card-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
}
.history-card-date {
  display: flex;
}
.date-text {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 22px;
  /* identical to box height, or 220% */

  color: #333333;
}
.date-value {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 22px;

  color: #aeaeae;
  margin-left: 4px;
}
</style>
