import actions from "./actions";
import mutations from "./mutations";

const state = {
  projects: [],
  project: {},
  agencyPoint: {},
  isFetching: false,
  error: null
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
