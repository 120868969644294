<template>
  <div>
    <v-row>
      <v-row align="center" justify="center">
        <v-card class="" width="100%" height="100%" outlined>
          <div style="display: flex;">
            <!-- <v-col cols="1"></v-col> -->
            <v-col cols="2 pb-0 pr-0" align="center" align-self="center">
              <h5 class="font-weight-regular black--text">ผู้ป่วยทั้งหมด</h5>
              <!-- <h6 class="font-weight-regular black--text">1250</h6> -->
            </v-col>
            <v-col cols="2 pb-0" align="center" align-self="center">
              <h5 class="font-weight-regular amber--text">รอส่งคูปอง</h5>
              <!-- <h6 class="font-weight-regular black--text">250</h6> -->
            </v-col>
            <v-col cols="2 pb-0" align="center" align-self="center">
              <h5 class="font-weight-regular orange--text">ได้รับคูปอง</h5>
              <!-- <h6 class="font-weight-regular black--text">250</h6> -->
            </v-col>
            <v-col cols="2 pb-0" align="center" align-self="center">
              <h5 class="font-weight-regular light-green--text">ยังไม่ได้สั่งอาหาร</h5>
              <!-- <h6 class="font-weight-regular black--text">550</h6> -->
            </v-col>
            <v-col cols="2 pb-0" align="center" align-self="center">
              <h5 class="font-weight-regular green--text">สั่งอาหาร</h5>
              <!-- <h6 class="font-weight-regular black--text">900</h6> -->
            </v-col>
            <v-col cols="2 pb-0" align="center" align-self="center">
              <h5 class="font-weight-regular blue--text">รับอาหาร</h5>
              <!-- <h6 class="font-weight-regular black--text">150</h6> -->
            </v-col>
          </div>
          <div class="" style="display: flex;">
            <v-col cols="2 pt-0 pr-0" align="center">
              <h6 class="font-weight-regular black--text">{{ all }}</h6>
            </v-col>
            <v-col cols="2 pt-0" align="center">
              <h6 class="font-weight-regular black--text">{{ waitingSend }}</h6>
            </v-col>
            <v-col cols="2 pt-0" align="center">
              <h6 class="font-weight-regular black--text">{{ all - waitingSend }}</h6>
            </v-col>
            <v-col cols="2 pt-0" align="center">
              <h6 class="font-weight-regular black--text">{{ sended }}</h6>
            </v-col>
            <v-col cols="2 pt-0" align="center">
              <h6 class="font-weight-regular black--text">{{ all - waitingSend - sended }}</h6>
            </v-col>
            <v-col cols="2  pt-0" align="center">
              <h6 class="font-weight-regular black--text">{{ comleted }}</h6>
            </v-col>
          </div>
        </v-card>
      </v-row>
      <v-row align="center" justify="center" v-if="owner">
        ภายใต้การรับผิดชอบของ {{ owner }}
        <v-card class="" width="100%" height="100%" outlined>
          <div style="display: flex;">
            <!-- <v-col cols="1"></v-col> -->
            <v-col cols="2 pb-0 pr-0" align="center" align-self="center">
              <h5 class="font-weight-regular black--text">ผู้ป่วยทั้งหมด</h5>
              <!-- <h6 class="font-weight-regular black--text">1250</h6> -->
            </v-col>
            <v-col cols="2 pb-0" align="center" align-self="center">
              <h5 class="font-weight-regular amber--text">รอส่งคูปอง</h5>
              <!-- <h6 class="font-weight-regular black--text">250</h6> -->
            </v-col>
            <v-col cols="2 pb-0" align="center" align-self="center">
              <h5 class="font-weight-regular orange--text">ได้รับคูปอง</h5>
              <!-- <h6 class="font-weight-regular black--text">250</h6> -->
            </v-col>
            <v-col cols="2 pb-0" align="center" align-self="center">
              <h5 class="font-weight-regular light-green--text">ยังไม่ได้สั่งอาหาร</h5>
              <!-- <h6 class="font-weight-regular black--text">550</h6> -->
            </v-col>
            <v-col cols="2 pb-0" align="center" align-self="center">
              <h5 class="font-weight-regular green--text">สั่งอาหาร</h5>
              <!-- <h6 class="font-weight-regular black--text">900</h6> -->
            </v-col>
            <v-col cols="2 pb-0" align="center" align-self="center">
              <h5 class="font-weight-regular blue--text">รับอาหาร</h5>
              <!-- <h6 class="font-weight-regular black--text">150</h6> -->
            </v-col>
          </div>
          <div class="pb-2" style="display: flex;">
            <v-col cols="2 pt-0 pr-0" align="center">
              <h6 class="font-weight-regular black--text">{{ allowner }}</h6>
            </v-col>
            <v-col cols="2 pt-0" align="center">
              <h6 class="font-weight-regular black--text">{{ waitingSendowner }}</h6>
            </v-col>
            <v-col cols="2 pt-0" align="center">
              <h6 class="font-weight-regular black--text">{{ allowner - waitingSendowner }}</h6>
            </v-col>
            <v-col cols="2 pt-0" align="center">
              <h6 class="font-weight-regular black--text">{{ sendedowner }}</h6>
            </v-col>
            <v-col cols="2 pt-0" align="center">
              <h6 class="font-weight-regular black--text">
                {{ allowner - waitingSendowner - sendedowner }}
              </h6>
            </v-col>
            <v-col cols="2  pt-0" align="center">
              <h6 class="font-weight-regular black--text">{{ comletedowner }}</h6>
            </v-col>
          </div>
        </v-card>
      </v-row>

      <v-row class="mt-5" align="center" justify="center">
        <v-card class="py-2" width="100%" outlined style="border: none">
          <div style="display: flex;">
            <v-col cols="3 px-1" align="center" align-self="center">
              <i-text-field
                v-model="search"
                label="ค้นหารายชื่อ"
                hide-details
                style="width: 100%"
              ></i-text-field>
            </v-col>
            <v-col cols="3 px-1">
              <v-select
                v-model="statusseled"
                :items="status"
                label="สถานะ"
                dense
                hide-details
                outlined
                style="width: 100%"
              ></v-select>
            </v-col>
            <v-col cols="3 px-1" align="center" align-self="center">
              <v-select
                v-model="owner"
                :items="owners"
                label="ผู้รับผิดชอบ"
                dense
                hide-details
                outlined
                style="width: 100%"
              ></v-select>
            </v-col>
            <v-col cols="3 px-1" align="center" align-self="center">
              <div class="filter-date">
                <!-- <i-date-range v-model="dateRange" label="วันที่" hide-details></i-date-range> -->

                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  left
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      width="80%"
                      v-model="date"
                      label="วันที่"
                      append-icon="mdi-calendar-week"
                      class="i-text-field"
                      readonly
                      outlined
                      hide-details
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" @input="menu = false" no-title scrollable>
                    <!-- <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)"> OK </v-btn> -->
                  </v-date-picker>
                </v-menu>
              </div>
            </v-col>
          </div>
        </v-card>
      </v-row>
      <!-- {{ date }} -->
      <v-row class="mt-5" align="center" justify="center">
        <v-data-table
          calculate-widths
          dense
          :headers="headers"
          :items="dataTable"
          mobile-breakpoint="320"
          item-key="name"
          class="elevation-1"
        >
          <template #item.full_name="{ item }">{{ item.firstName }} {{ item.lastName }}</template>
          <template #item.status_text="{ item }"
            ><div :style="{ color: statusColor(item.status) }">{{ statusTexS(item.status) }}</div>
          </template>
          <template #item.actions="{ item }">
            <v-icon small class="mr-2" @click="detailItem(item)" color="#707CFF">
              mdi-format-list-bulleted
            </v-icon>
            <!-- <v-icon v-show="item.status == 1" small @click="deleteItem(item)" color="#FF717C">
              mdi-delete
            </v-icon> -->
          </template>
        </v-data-table>
      </v-row>
      <DialogDetail v-model="dialog" :data="data_detail" @getItems="getItems"></DialogDetail>
    </v-row>
  </div>
</template>

<script>
import DialogDetail from "../modules/dialog_detail.vue";
// import daterang from "components/inputs/date-range.vue";
const baseUrl = process.env.VUE_APP_BASE_URL_API;
import axios from "clients/axios";
export default {
  components: {
    DialogDetail
  },
  data: () => ({
    statusseled: null,
    baseUrl,
    id: "",
    ets: "",
    menu: false,
    search: "",
    dialog: false,
    data_detail: {},
    owner: "",
    // items: ["ทั้งหมด", "รอส่ง", "ส่งแล้ว", "ใช้แล้ว", "ส่งสำเร็จ"],
    status: [
      {
        text: "ทั้งหมด",
        value: null
      },
      {
        text: "รอส่งคูปอง",
        value: "0"
      },
      {
        text: "ยังไม่ได้สั่งอาหาร",
        value: "1"
      },
      {
        text: "สั่งอาหาร",
        value: "2"
      },
      {
        text: "รับอาหาร",
        value: "3"
      }
    ],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    desserts: [],
    headers: [
      {
        text: "ชื่อ-สกุล",
        align: "start",
        sortable: false,
        value: "full_name"
      },
      { text: "เบอร์โทร", value: "phone" },
      { text: "สถานะ", value: "status_text" },
      { text: "ผู้รับผิดชอบ", value: "owner" },
      { text: "", value: "actions", sortable: false, width: "1%" }
    ]
  }),
  computed: {
    dataTable() {
      // console.log(this.statusseled);
      return this.desserts.filter((item) => {
        return (
          (this.statusseled == null || item.status == this.statusseled) &&
          (!this.owner || item.owner == this.owner) &&
          (!this.search ||
            item.firstName.includes(this.search) ||
            item.lastName.includes(this.search) ||
            item.phone.includes(this.search))
        );
      });
    },
    dataTableOwner() {
      // console.log(this.statusseled);
      return this.desserts.filter(item => {
        return item.owner == this.owner;
      });
    },
    all() {
      return this.desserts.length;
    },
    waitingSend() {
      return this.desserts.filter((item) => item.status == "0").length;
    },
    sended() {
      return this.desserts.filter((item) => item.status == "1").length;
    },
    ordered() {
      return this.desserts.filter((item) => item.status == "2").length;
    },
    comleted() {
      return this.desserts.filter(item => item.status == "3").length;
    },
    owners() {
      let tmp = [];
      this.desserts.forEach(item => {
        if (item.owner && !tmp.includes(item.owner)) {
          tmp.push(item.owner);
        }
      });

      return tmp;
    },
    allowner() {
      return this.dataTableOwner.length;
    },
    waitingSendowner() {
      return this.dataTableOwner.filter(item => item.status == "0").length;
    },
    sendedowner() {
      return this.dataTableOwner.filter(item => item.status == "1").length;
    },
    orderedowner() {
      return this.dataTableOwner.filter(item => item.status == "2").length;
    },
    comletedowner() {
      return this.dataTableOwner.filter(item => item.status == "3").length;
    }
  },
  async mounted() {
    this.id = await this.$route.params.id;
    this.ets = await this.$route.params.ets;
    this.getItems();
  },
  watch: {
    date() {
      this.getItems();
    }
  },
  methods: {
    async getItems() {
      try {
        const res = await axios.post(`${baseUrl}/coupon/getcouponhidetails`, {
          day: this.date,
          ets: this.ets,
          agencyId: this.id
        });
        const { data } = res.data;
        this.desserts = data;
      } catch (error) {
        console.log(error);
      }
    },
    statusTexS(val) {
      switch (val) {
        case "0":
          return "รอส่งคูปอง";
        case "1":
          // code block
          return "ยังไม่ได้สั่งอาหาร";
        case "2":
          // code block
          return "สั่งอาหาร";
        case "3":
          // code block
          return "รับอาหาร";
        default:
          return "";
        // code block
      }
    },
    statusColor(val) {
      switch (val) {
        case "0":
          return "#E9A016";
        case "1":
          // code block
          return "#8bc34a";
        case "2":
          // code block
          return "#4caf50";
        case "3":
          // code block
          return "#2196f3";
        default:
          return "";
        // code block
      }
    },
    detailItem(val) {
      // console.log(val);
      this.dialog = true;
      this.data_detail = val;
      // this.$refs.DialogDetail.open();
      // this.$emit("detailItem");
    }
  }
};
</script>

<style scoped>
.v-data-footer {
  padding: inherit !important;
}
</style>
