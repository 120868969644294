<template>
  <div>
    <div class="agency-image-container">
      <div class="agency-image">
        <img :src="localImage" />
      </div>
    </div>
    <div class="agency-detail">
      <i-card outlined>
        <div>
          <div class="agency-group-content">
            <div class="agency-title">ชื่อโครงการ</div>
            <div class="agency-sub-title">
              {{ agency.name }}
            </div>
          </div>
          <div class="agency-group-content">
            <div class="agency-title">ชื่อมูลนิธิ</div>
            <div class="agency-sub-title">
              {{ agency.agencyName }}
            </div>
          </div>
          <div class="agency-group-content">
            <div class="agency-title">วันที่สิ้นสุดกิจกรรม</div>
            <div class="agency-sub-title">{{ datePreview }}</div>
          </div>
          <div class="agency-group-content">
            <div class="agency-title">รายละเอียดการร่วมบริจาค</div>
            <div class="agency-sub-title">
              {{ agency.details }}
            </div>
          </div>
          <!-- <div class="agency-group-content">
            <div class="agency-title">สถานที่ร่วมกิจกรรม</div>
            <div class="agency-sub-title">
              {{ agency.location }}
            </div>
          </div> -->
        </div>
      </i-card>
    </div>
    <div @click="handleClickDialog">
      <v-checkbox v-model="checkbox" disabled>
        <template v-slot:label>
          <div
            style="font-family: Prompt;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px; color: #333333;"
          >
            ยอมรับเงื่อนไขเข้าร่วมดูแลโครงการ
          </div>
        </template>
      </v-checkbox>
    </div>
    <div class="detail-action">
      <i-button style="height: 45px" @click="handleClick" :disabled="!checkbox"
        >ยืนยันการดูแลโครงการ</i-button
      >
    </div>
    <FoundationDialog v-model="localDialog" @handel-accept="accept" />
  </div>
</template>

<script>
import moment from "moment";
import FoundationDialog from "./modules/foundation-dialog.vue";
import { mapState, mapActions } from "vuex";
import axios from "clients/axios";

export default {
  components: {
    FoundationDialog
  },
  data: () => ({
    image: require("assets/image/preview.png"),
    checkbox: null,
    localDialog: null,
    id: null
  }),
  async mounted() {
    await this.getAgency(this.$route.params.id);
    this.id = this.$route.params.id;
  },
  computed: {
    ...mapState("agency", ["isFetching", "agency"]),
    localImage() {
      const baseUrl = process.env.VUE_APP_BASE_URL_API;
      const image = require("assets/image/preview.png");
      return this.agency.image ? `${baseUrl}media/image${this.agency.image}` : image;
    },
    datePreview() {
      return this.localStartDate + `${this.localEndDate ? ` - ${this.localEndDate}` : ""}`;
    },
    localStartDate() {
      return this.agency.startTime ? moment(this.agency.startTime).format("DD/MM/YYYY") : "";
    },
    localEndDate() {
      return this.agency.endTime ? moment(this.agency.endTime).format("DD/MM/YYYY") : "ไม่สิ้นสุด";
    }
  },
  methods: {
    ...mapActions("agency", ["getAgency"]),
    async handleClick() {
      const { id } = this;
      await axios.post("agency/approve", { agencyId: id });
      this.$dialog({
        // title: "Error",
        showCancel: false,
        type: "success",
        desc: "เข้าร่วมการดูแลโครงการเรียบร้อยแล้ว"
      }).then(() => {
        this.$router.push("/project");
      });
    },
    handleClickDialog() {
      this.localDialog = true;
    },
    accept() {
      this.checkbox = true;
    }
  }
};
</script>

<style scoped>
.agency-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.agency-image {
  overflow: hidden;
  border-radius: 6px;
  display: flex;
}
.agency-detail {
  margin-top: 10px;
}
.agency-group-content {
  margin: 15px 0;
}
.agency-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  /* margin: 5px 0; */
  margin-bottom: 15px;
}
.agency-sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #aeaeae;
  margin: 5px 0;
}
</style>
