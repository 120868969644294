import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import vuetify from "plugins/vuetify";
import DialogPlugin from "plugins/dialog";
import Button from "components/button.vue";
import Card from "components/card/index.vue";
import TextField from "components/inputs/text-field.vue";
import Select from "components/inputs/select.vue";
import SelectSS from "components/inputs/select-ss.vue";
import DateRange from "components/inputs/date-range.vue";
import DateRangeHistory from "components/inputs/date-range-history.vue";

import SweetAlertIcons from "vue-sweetalert-icons";
import VueLoading from "vuejs-loading-plugin";

import "layouts/index";

Vue.config.productionTip = false;

Vue.component("i-button", Button);
Vue.component("i-card", Card);
Vue.component("i-text-field", TextField);
Vue.component("i-select", Select);
Vue.component("i-select-ss", SelectSS);
Vue.component("i-date-range", DateRange);
Vue.component("i-date-range-history", DateRangeHistory);

Vue.use(DialogPlugin);

Vue.use(SweetAlertIcons);

Vue.use(VueLoading, {
  text: "loading" // default 'Loading'
});

Vue.prototype.$eventBus = new Vue();

export const app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
