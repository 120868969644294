import {
  getCouponsService,
  botChatListRoomByEmail,
  sendCoupon,
  getQuotapoint,
  exchangeCoupon,
  getQuotapointHistory,
  getCouponAmount,
  getDonateAmount
} from "services/coupon.service";

export default {
  getCoupons: async ({ commit }, payload) => {
    try {
      const result = await getCouponsService(payload);
      commit("GET_COUPON_SUCSESS", result);
    } catch (error) {
      console.log(error);
    }
  },
  selectCoupon: ({ commit }, payload) => {
    commit("SELECT_COUPON", payload);
  },
  async getListRoomByEmail({ commit }, payload) {
    try {
      const result = await botChatListRoomByEmail(payload);
      if (result) {
        return { email: result.one_email, userId: result.one_id };
      }
      return null;
    } catch (error) {
      commit("GET_FAILURE", error.message);
    }
  },
  async sendCoupon({ commit }, payload) {
    try {
      await sendCoupon(payload);
    } catch (error) {
      commit("GET_FAILURE", error.message);
    }
  },
  async getQuotapoint({ commit }, payload) {
    try {
      const result = await getQuotapoint(payload);
      commit("GET_QUOTAPOINT_SUCSESS", result);
    } catch (error) {
      commit("GET_FAILURE", error.message);
    }
  },
  async exchangeCoupon({ commit }, payload) {
    try {
      await exchangeCoupon(payload);
    } catch (error) {
      commit("GET_FAILURE", error.message);
    }
  },
  async getQuotapointHistory({ commit }, payload) {
    try {
      const result = await getQuotapointHistory(payload);
      commit("GET_QUOTAHISTORY_SUCSESS", result);
    } catch (error) {
      commit("GET_FAILURE", error.message);
    }
  },
  async getCouponAmount({ commit }, payload) {
    try {
      const result = await getCouponAmount(payload);
      commit("GET_COUPONAMOUNT_SUCSESS", result);
    } catch (error) {
      commit("GET_FAILURE", error.message);
    }
  },
  async getDonateAmount({ commit }, payload) {
    try {
      const result = await getDonateAmount(payload);
      commit("GET_DONATEAMOUNT_SUCSESS", result);
    } catch (error) {
      commit("GET_FAILURE", error.message);
    }
  }
};
