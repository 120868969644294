// import { GET_API, GET_FAILURE, GET_PROJECTS_SUCCESS } from "./constant";

import { approveCoupon,rejectCoupon,getApproveCoupon,getWaitingCoupon,approveCouponAll,getEmailOverService
 } from "services/home_isolation.service";


export default {
  async approveCoupon({ commit }, payload) {
    try {
      await approveCoupon(payload);
    } catch (error) {
      commit("GET_FAILURE", error.message);
    }
  },
   async approveCouponAll({ commit }, payload) {
    try {
      await approveCouponAll(payload);
    } catch (error) {
      commit("GET_FAILURE", error.message);
    }
  },
   async rejectCoupon({ commit }, payload) {
    try {
      await rejectCoupon(payload);
    } catch (error) {
      commit("GET_FAILURE", error.message);
    }
  },
   getCoupons: async ({ commit }, payload) => {
    try {
      const result = await getApproveCoupon(payload);
      commit("GET_COUPON_SUCSESS", result);
    } catch (error) {
      console.log(error);
    }
  },
  getCouponWaiting: async ({ commit }, payload) => {
    try {
      const result = await getWaitingCoupon(payload);
      commit("GET_COUPON_WAITING_SUCSESS", result);
    } catch (error) {
      console.log(error);
    }
  },
  getEmailOver: async ({ commit }, payload) => {
    try {
      const result = await getEmailOverService(payload);
      commit("GET_EMAIL_OVER_SUCSESS", result);
    } catch (error) {
      console.log(error);
    }
  },
};
