import axios from "clients/axios";

export const getHistoryService = async body => {
  try {
    const res = await axios.post("/donate/getdonate", body);
    const { data } = res.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
