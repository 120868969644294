<template>
  <div class="point-detail">
    <i-card outlined>
      <div class="point-content-container">
        <div class="point-content-left">แต้มของฉัน (Local life)</div>
        <div class="point-content-right">{{ userPoint.points }} แต้ม</div>
      </div>
      <div class="point-content-container">
        <div class="point-content-left">หน่วยแต้มการบริจาค</div>
        <div class="point-content-right">1 แต้ม = 1 บาท</div>
      </div>
    </i-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  mounted() {
    this.getPoint();
  },
  computed: {
    ...mapState("user", ["userPoint"])
  },
  methods: {
    ...mapActions("user", ["getPoint"])
  }
};
</script>

<style scoped>
.point-content-container {
  display: flex;
  margin: 5px 0;
  padding: 5px;
}
.point-content-left {
  flex: 2;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}
.point-content-right {
  flex: 1;
  text-align: right;
  color: #1e4d94;
}
</style>
