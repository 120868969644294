<template>
  <div>
    <CardItem
      title="ชื่อมูลนิธิ"
      desc="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
      style="padding: 1px"
    />
    <br />
    <PointDetail />
    <br />
    <DonationForm />
  </div>
</template>

<script>
import CardItem from "components/card/card-item.vue";
import PointDetail from "./modules/point-detail";
import DonationForm from "./modules/donation-form";

export default {
  components: {
    CardItem,
    PointDetail,
    DonationForm
  },
  data: () => ({
    image: require("assets/image/preview.png")
  })
};
</script>

<style scoped></style>
